import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1015.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1015.000000) scale(0.100000,-0.100000)">
          <path d="M4990 9545 c-315 -26 -601 -73 -840 -138 -30 -9 -91 -25 -135 -37
-159 -43 -474 -153 -605 -212 -77 -35 -423 -207 -455 -226 -16 -10 -51 -30
-77 -45 -26 -14 -64 -37 -85 -50 -95 -61 -212 -138 -258 -171 -27 -20 -59 -41
-69 -48 -50 -34 -323 -255 -392 -319 -134 -123 -359 -356 -416 -431 -15 -20
-33 -42 -40 -50 -74 -79 -241 -309 -333 -458 -12 -19 -37 -60 -56 -89 -84
-136 -250 -470 -312 -631 -133 -341 -200 -596 -261 -990 -46 -290 -48 -818 -5
-1105 48 -323 118 -618 205 -861 25 -71 49 -140 54 -154 29 -87 183 -407 278
-575 39 -70 236 -374 268 -415 7 -8 57 -71 111 -140 55 -69 124 -152 154 -184
30 -33 69 -75 87 -95 113 -124 310 -307 449 -419 23 -18 54 -44 70 -58 85 -73
427 -306 558 -379 257 -144 441 -233 663 -319 316 -124 628 -213 942 -270 355
-63 443 -70 910 -70 493 0 571 7 985 86 377 72 833 221 1143 372 40 20 75 36
78 36 5 0 228 120 316 170 14 8 44 25 65 37 86 48 424 283 506 352 12 10 58
49 102 86 68 57 243 214 279 251 22 22 215 230 233 250 59 69 164 200 207 259
17 22 32 42 35 45 4 3 11 12 16 21 6 9 53 81 106 160 127 192 156 242 268 469
29 58 58 116 66 130 7 14 23 50 35 80 12 30 28 69 35 85 13 30 38 97 90 245
36 102 101 344 125 465 70 355 90 550 90 878 -1 549 -93 1031 -297 1557 -46
118 -208 455 -263 545 -16 28 -33 57 -37 65 -15 30 -67 115 -73 120 -3 3 -25
37 -49 75 -42 67 -219 308 -258 350 -10 11 -34 40 -53 64 -68 87 -284 312
-422 438 -70 65 -391 324 -430 348 -9 6 -68 44 -130 86 -142 96 -194 129 -233
148 -11 6 -51 29 -90 51 -299 172 -736 354 -1060 440 -44 12 -105 28 -135 37
-135 37 -443 96 -585 113 -344 41 -762 50 -1075 25z m850 -60 c526 -46 1060
-179 1527 -381 110 -47 370 -175 433 -212 19 -11 55 -32 80 -45 134 -73 348
-218 545 -369 291 -224 599 -535 818 -826 17 -24 37 -48 42 -55 31 -36 172
-248 234 -352 93 -155 80 -132 149 -270 248 -496 380 -949 437 -1505 40 -382
0 -998 -85 -1310 -5 -19 -16 -69 -25 -110 -9 -41 -23 -95 -31 -120 -32 -105
-97 -297 -109 -325 -7 -16 -27 -68 -45 -115 -41 -108 -156 -348 -223 -465
-211 -372 -434 -663 -742 -970 -182 -182 -297 -284 -445 -395 -19 -14 -54 -41
-79 -61 -25 -19 -66 -49 -91 -65 -25 -16 -49 -33 -55 -37 -21 -17 -156 -103
-272 -173 -103 -62 -478 -254 -497 -254 -3 0 -34 -13 -68 -29 -222 -102 -715
-251 -993 -300 -598 -105 -1112 -116 -1655 -37 -259 39 -209 29 -475 91 -434
103 -850 266 -1245 488 -109 61 -316 190 -345 214 -5 4 -23 17 -40 27 -28 18
-174 125 -185 136 -3 3 -21 17 -42 32 -20 15 -42 34 -50 41 -7 7 -20 18 -29
23 -48 27 -403 369 -514 494 -172 193 -352 440 -500 686 -89 147 -241 456
-286 582 -12 32 -27 71 -35 88 -72 162 -184 590 -218 834 -61 445 -63 861 -6
1240 70 470 201 876 425 1321 42 84 60 117 130 238 26 44 217 331 238 357 9
10 51 64 94 120 141 183 369 424 543 574 52 46 109 94 125 109 126 112 561
411 675 464 8 4 31 17 50 29 61 37 321 165 430 211 327 139 766 272 1045 317
28 4 112 17 187 29 75 12 203 28 285 35 209 19 677 19 893 1z"/>
          <path d="M7044 7896 c-31 -14 -49 -44 -75 -121 -7 -22 -21 -49 -31 -59 -17
-20 -18 -20 -79 9 l-61 30 -25 -30 c-53 -62 -79 -92 -150 -172 -70 -76 -77
-82 -124 -88 -51 -7 -51 -7 -45 -39 7 -36 31 -58 98 -92 27 -13 49 -30 49 -37
1 -6 0 -39 -1 -73 -1 -45 2 -64 15 -74 9 -7 43 -56 76 -109 32 -53 65 -104 73
-114 14 -18 100 -42 191 -54 48 -6 80 -27 70 -44 -16 -25 -272 28 -314 65 -9
7 -28 16 -43 20 -16 3 -28 13 -28 21 0 9 -9 15 -21 15 -12 0 -34 11 -51 25
-40 34 -88 33 -147 -3 -25 -16 -60 -33 -77 -39 -17 -6 -50 -23 -73 -37 l-41
-26 1 -78 c1 -42 4 -90 8 -106 8 -40 22 -20 45 64 9 36 24 75 32 88 8 12 12
28 8 35 -4 7 -4 9 1 5 4 -4 20 3 35 16 26 21 33 22 91 14 91 -12 166 -37 226
-75 29 -18 56 -33 61 -33 5 0 18 -10 30 -23 22 -23 29 -56 52 -237 30 -235 34
-254 58 -255 4 0 21 -14 37 -30 17 -17 44 -38 60 -47 40 -23 69 -42 75 -48 7
-8 59 -30 70 -30 5 0 3 17 -4 38 -7 20 -18 69 -25 107 -33 201 -66 289 -119
316 -48 25 -62 39 -62 64 0 50 153 139 262 152 69 8 45 26 -37 27 -36 1 -65 2
-65 3 0 14 33 69 67 113 24 30 81 107 128 170 l85 115 0 116 0 115 -110 112
c-61 61 -115 123 -120 137 -6 15 -13 57 -16 96 -9 89 -18 102 -60 85z"/>
          <path d="M8575 7248 c-3 -13 -9 -95 -12 -183 l-6 -160 -37 -35 c-21 -20 -61
-46 -89 -59 -56 -27 -58 -32 -41 -119 6 -33 6 -88 0 -154 -6 -57 -13 -143 -17
-191 l-6 -88 -95 -94 -95 -94 6 -68 c4 -37 9 -84 13 -103 5 -34 3 -37 -65 -94
-39 -32 -71 -62 -71 -66 0 -12 50 -30 85 -30 41 0 43 -6 13 -33 -30 -25 -291
-152 -377 -182 -36 -13 -97 -35 -136 -50 -136 -51 -322 -85 -465 -84 -112 0
-262 20 -267 36 -6 17 69 66 205 133 70 35 135 67 145 72 9 4 52 22 95 39 42
16 91 36 107 44 27 12 160 54 340 107 81 24 103 38 58 38 -51 0 -284 -38 -343
-56 -282 -85 -500 -196 -624 -319 -23 -22 -50 -44 -61 -50 -32 -17 -46 -55
-39 -105 11 -73 52 -138 154 -241 70 -71 229 -167 401 -242 226 -99 536 -98
719 2 191 104 331 283 399 511 22 74 32 227 22 325 -24 212 -6 446 48 622 16
54 42 141 56 193 15 52 33 118 42 145 8 28 30 106 48 175 19 69 43 153 54 187
l19 62 -71 116 c-72 117 -98 138 -112 93z"/>
          <path d="M8145 6911 c-3 -6 4 -25 14 -43 11 -18 34 -59 52 -91 25 -45 35 -55
45 -46 8 7 14 31 14 58 0 48 -16 72 -57 85 -11 3 -29 15 -41 26 -11 11 -24 16
-27 11z"/>
          <path d="M6393 6113 c-14 -5 -17 -29 -5 -37 4 -2 14 -1 21 3 16 11 2 40 -16
34z"/>
          <path d="M6460 6080 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10
-8 0 -14 -4 -14 -10z"/>
          <path d="M6320 6016 c0 -14 5 -26 10 -26 6 0 10 9 10 19 0 11 -4 23 -10 26 -6
4 -10 -5 -10 -19z"/>
          <path d="M6486 6022 c-2 -4 -1 -14 5 -22 6 -11 9 -12 9 -2 0 8 5 10 13 6 8 -5
8 -2 -2 9 -15 19 -18 20 -25 9z"/>
          <path d="M6305 5968 c-4 -10 -4 -26 0 -35 8 -17 8 -17 12 0 3 9 3 25 0 35 -4
16 -4 16 -12 0z"/>
          <path d="M6308 5903 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path d="M6602 5875 c-14 -16 -34 -30 -45 -30 -27 0 -30 -18 -29 -155 1 -144
-4 -142 153 -73 45 20 58 32 67 57 5 17 24 70 42 119 17 48 30 92 28 98 -2 6
-42 11 -97 12 -92 2 -94 2 -119 -28z"/>
          <path d="M6370 5883 c0 -14 34 -16 65 -4 16 7 11 9 -22 12 -30 3 -43 1 -43 -8z" />
          <path d="M6315 5880 c-4 -6 0 -19 9 -29 18 -20 35 -6 -159 -133 -128 -83 -179
-111 -303 -163 -36 -16 -60 -40 -134 -139 -50 -67 -93 -127 -95 -133 -7 -18
-43 -16 -81 2 -42 21 -81 11 -88 -24 -6 -32 -37 -133 -81 -267 -18 -56 -33
-109 -33 -118 0 -9 -9 -42 -19 -73 -11 -32 -25 -84 -31 -115 -9 -47 -15 -58
-31 -58 -14 0 -19 -7 -19 -30 0 -37 -25 -134 -46 -179 -12 -24 -13 -36 -5 -44
6 -6 11 -42 11 -79 0 -56 -4 -71 -20 -85 -11 -10 -20 -28 -20 -40 0 -13 -5
-23 -12 -23 -7 0 -6 -6 4 -17 12 -14 16 -48 17 -148 l2 -130 36 -38 c55 -55
54 -57 107 208 8 39 22 120 31 180 9 61 18 119 20 130 3 14 26 30 72 50 37 16
119 53 180 82 62 29 117 53 121 53 5 0 21 11 36 25 15 14 36 25 46 25 10 0 21
4 25 9 3 5 18 12 34 16 16 3 35 13 42 21 13 16 6 18 -19 5 -9 -5 -37 -15 -62
-22 -25 -8 -53 -22 -62 -31 -10 -10 -26 -18 -35 -17 -11 0 -13 3 -5 6 6 2 12
11 12 19 0 17 -40 18 -90 4 -63 -19 -210 -40 -235 -34 -30 8 -38 30 -25 74 6
19 10 46 10 60 0 14 12 52 26 85 14 33 31 73 37 90 16 39 51 71 106 96 52 23
92 21 171 -11 30 -12 64 -24 75 -28 11 -3 33 -18 48 -34 16 -15 33 -28 38 -28
5 0 29 -19 52 -43 23 -24 56 -52 72 -62 17 -11 43 -34 59 -53 16 -18 38 -38
49 -43 16 -9 18 -8 12 10 -8 26 -74 101 -89 101 -13 0 -76 54 -76 64 0 4 10 4
21 1 20 -7 20 -5 8 10 -8 9 -24 26 -36 37 -13 12 -23 28 -23 35 0 7 -7 13 -15
13 -8 0 -15 6 -15 13 0 7 -18 32 -40 55 -48 49 -50 69 -11 83 18 7 51 38 85
82 30 40 90 114 133 166 l78 93 120 50 c66 27 124 53 130 59 5 5 12 69 15 142
5 129 5 131 29 137 61 14 20 40 -73 45 -21 1 -41 6 -45 11 -16 23 -39 35 -46
24z m106 -65 c33 -14 33 -14 8 -15 -15 0 -32 7 -39 15 -16 19 -11 19 31 0z
m-696 -705 c-3 -5 -1 -10 5 -10 6 0 8 -5 4 -11 -4 -7 -10 -7 -16 -1 -9 9 -3
32 8 32 3 0 2 -4 -1 -10z m47 -22 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9
9 -15 0 -24z m43 -38 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10
2 0 7 -4 10 -10z m-58 -26 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m143 -84 c0 -5 -9 -6 -20 -3 -11 3 -17 9 -15 14 7 10 35 1 35 -11z m20
-24 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m-203 -372 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
          <path d="M6480 5876 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />
          <path d="M5042 5515 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
          <path d="M5065 5385 c3 -27 6 -70 7 -94 2 -27 8 -46 17 -49 11 -4 13 4 6 39
-4 24 -9 58 -10 74 -2 17 -8 41 -14 55 -9 21 -10 17 -6 -25z"/>
          <path d="M5101 5193 c1 -16 3 -45 6 -65 4 -30 7 -35 18 -25 9 8 10 19 4 35 -5
13 -9 37 -9 53 0 16 -4 29 -10 29 -5 0 -9 -12 -9 -27z"/>
          <path d="M4339 5193 c-19 -5 -49 -54 -49 -81 0 -12 -4 -23 -9 -26 -11 -7 -24
-92 -42 -258 -12 -114 -10 -173 11 -393 5 -55 12 -136 15 -180 4 -44 8 -87 11
-95 10 -29 44 -235 44 -267 0 -17 5 -35 10 -38 6 -3 10 -16 10 -27 0 -22 3
-34 40 -153 26 -85 62 -164 74 -165 5 0 6 21 2 48 -7 50 -34 270 -41 337 -2
22 -7 81 -10 130 -3 50 -10 117 -16 150 -5 33 -13 188 -19 344 -9 279 -4 356
25 432 9 24 50 42 78 35 46 -11 167 -147 167 -186 0 -6 4 -10 8 -10 5 0 23
-22 41 -50 18 -27 37 -50 42 -50 5 0 9 -8 9 -17 0 -22 29 -53 44 -47 8 3 12
-8 13 -32 1 -41 15 -54 56 -54 15 0 27 -4 27 -10 0 -5 4 -10 8 -10 5 0 9 7 9
15 0 8 -9 15 -19 15 -11 0 -18 7 -18 20 0 26 25 36 47 19 9 -8 14 -9 12 -4 -3
6 -13 27 -23 49 -9 21 -30 53 -47 70 -16 17 -29 36 -29 41 0 6 -12 24 -27 41
-64 70 -93 108 -93 120 0 7 -9 16 -20 19 -11 3 -20 12 -20 19 0 21 -185 203
-227 224 -46 24 -73 31 -94 25z m371 -405 c0 -4 -4 -8 -10 -8 -5 0 -10 4 -10
9 0 5 -3 18 -6 28 -5 16 -4 16 10 -1 9 -11 16 -23 16 -28z m144 -149 c-8 -14
-54 33 -54 55 0 15 6 12 30 -14 16 -18 27 -36 24 -41z"/>
          <path d="M5115 5040 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
          <path d="M5120 4973 c0 -10 7 -27 15 -37 15 -19 15 -19 15 1 0 28 -10 53 -21
53 -5 0 -9 -8 -9 -17z"/>
          <path d="M5171 4914 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path d="M5175 4815 c-5 -11 -13 -23 -18 -26 -6 -4 -5 -14 3 -25 9 -16 11 -16
6 -2 -3 10 2 21 10 26 9 5 14 17 12 28 -5 18 -5 18 -13 -1z"/>
          <path d="M1674 4789 c-11 -18 -35 -111 -44 -164 -7 -48 0 -339 20 -845 6 -135
13 -340 16 -456 l7 -212 36 -20 c53 -30 123 -38 141 -17 36 44 40 83 39 440
-1 193 -3 342 -6 333 -2 -10 -8 -18 -12 -18 -10 0 -34 70 -27 77 3 4 6 0 6 -9
0 -9 8 -14 22 -13 30 2 36 25 7 25 -18 0 -20 3 -11 12 7 7 31 12 55 12 23 -1
32 1 20 3 -33 6 -29 33 5 33 l27 1 -27 24 c-29 25 -38 65 -15 65 9 0 12 10 9
30 -2 17 1 37 8 45 9 10 9 15 1 15 -6 0 -11 17 -11 39 0 22 -5 41 -12 44 -10
3 -10 9 0 26 14 24 16 51 3 51 -29 0 -13 93 18 110 24 12 31 42 14 58 -11 11
-15 8 -23 -13 -5 -14 -13 -25 -18 -25 -11 0 -35 -70 -30 -89 6 -29 -31 -23
-44 6 -7 15 -16 30 -20 33 -4 3 -12 39 -19 80 -6 41 -15 80 -19 85 -4 6 -11
28 -15 50 -10 55 -64 183 -81 189 -7 3 -16 0 -20 -5z"/>
          <path d="M5202 4750 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
          <path d="M5165 4700 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
          <path d="M2040 4651 c-7 -15 -7 -21 0 -21 12 0 25 28 17 36 -3 3 -10 -4 -17
-15z"/>
          <path d="M2580 4644 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
          <path d="M2537 4629 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
          <path d="M5170 4590 c0 -22 5 -40 10 -40 6 0 10 18 10 40 0 22 -4 40 -10 40
-5 0 -10 -18 -10 -40z"/>
          <path d="M2500 4605 c-10 -12 -10 -15 4 -15 9 0 16 7 16 15 0 8 -2 15 -4 15
-2 0 -9 -7 -16 -15z"/>
          <path d="M2010 4561 c0 -23 5 -41 10 -41 6 0 10 15 10 34 0 19 -4 38 -10 41
-6 4 -10 -10 -10 -34z"/>
          <path d="M1977 4551 c-19 -32 -22 -51 -8 -51 10 0 36 68 29 75 -2 3 -12 -9
-21 -24z"/>
          <path d="M2457 4553 c-54 -55 -131 -217 -148 -308 -29 -159 -10 -262 63 -337
43 -45 49 -48 93 -46 l47 2 -25 21 c-14 11 -35 41 -46 66 l-22 46 22 37 c11
20 24 36 27 36 4 0 18 17 32 38 14 20 29 40 35 44 18 14 -5 8 -37 -8 -23 -12
-29 -13 -26 -2 2 7 14 17 26 23 31 14 29 37 -3 29 -21 -5 -27 -1 -36 22 -12
32 -5 164 9 173 5 3 14 20 20 38 6 17 20 41 31 52 26 26 27 51 2 51 -11 0 -23
9 -26 20 -8 25 -17 25 -38 3z m38 -62 c-9 -8 -25 21 -25 44 0 16 3 14 15 -9 9
-16 13 -32 10 -35z"/>
          <path d="M4920 4562 c0 -4 5 -13 12 -20 16 -16 28 -15 28 2 0 8 -7 17 -16 20
-21 8 -24 8 -24 -2z"/>
          <path d="M3507 4510 c-21 -31 -21 -53 0 -101 11 -25 21 -35 30 -32 12 5 12 7
2 14 -8 4 -19 23 -25 41 -9 26 -8 38 3 55 21 32 11 53 -10 23z"/>
          <path d="M4962 4513 c21 -45 33 -63 41 -63 5 0 6 7 3 16 -3 9 -6 20 -6 24 0 5
-10 14 -21 21 -12 6 -20 7 -17 2z"/>
          <path d="M4852 4499 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
          <path d="M4904 4495 c4 -10 -2 -19 -17 -25 -12 -5 -16 -9 -9 -9 6 -1 12 -7 12
-15 0 -25 25 -37 45 -22 20 14 14 48 -7 42 -7 -1 -10 -1 -5 1 14 9 7 43 -9 43
-10 0 -14 -5 -10 -15z"/>
          <path d="M4960 4427 c0 -9 -6 -17 -14 -17 -22 0 -25 -24 -6 -49 l17 -24 6 49
c3 27 3 51 1 53 -2 2 -4 -3 -4 -12z"/>
          <path d="M5027 4414 c-13 -13 -7 -24 14 -24 11 0 19 5 17 12 -5 14 -22 21 -31
12z"/>
          <path d="M3458 4404 c-3 -5 -2 -18 2 -28 5 -16 8 -17 12 -4 7 16 -7 45 -14 32z" />
          <path d="M4979 4383 c-1 -5 0 -12 1 -18 1 -5 2 -27 2 -47 -1 -21 3 -40 9 -43
5 -4 9 3 9 14 0 10 5 21 11 23 16 5 0 78 -17 78 -8 0 -15 -3 -15 -7z"/>
          <path d="M3397 4273 c-21 -43 -41 -84 -46 -92 -5 -10 -25 -15 -58 -15 -28 -1
-54 -4 -58 -8 -12 -12 27 -97 52 -113 20 -13 23 -22 18 -43 -13 -52 -16 -188
-4 -229 13 -48 84 -120 127 -128 15 -3 44 -14 65 -24 30 -16 37 -17 37 -5 0 8
-4 14 -10 14 -16 0 -50 38 -50 55 0 9 -10 26 -23 39 -13 13 -24 30 -25 37 0 8
-6 75 -13 149 l-12 134 27 21 c15 11 46 27 69 36 27 11 41 22 39 32 -1 9 7 22
18 30 11 7 17 16 14 20 -11 10 -35 -5 -44 -28 -10 -26 -50 -41 -50 -19 0 8 4
14 9 14 5 0 6 20 4 44 -4 29 0 51 10 67 8 13 18 35 22 49 7 23 6 24 -9 11 -10
-8 -24 -27 -32 -43 -17 -33 -30 -36 -37 -8 -3 13 0 20 9 20 17 0 17 8 1 38
-12 21 -15 19 -50 -55z m36 -40 c-7 -2 -13 -12 -13 -20 0 -9 -3 -14 -6 -10
-10 10 4 37 18 36 10 0 10 -2 1 -6z m-69 -308 c3 -8 1 -15 -4 -15 -6 0 -10 7
-10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m62 -232 c-7 -7 -26 7 -26 19 0 6 6 6
15 -2 9 -7 13 -15 11 -17z"/>
          <path d="M5066 4324 c-3 -8 -10 -12 -16 -9 -6 3 -7 -1 -4 -9 7 -19 41 -21 47
-3 10 29 -16 51 -27 21z"/>
          <path d="M1970 4320 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30 0z" />
          <path d="M5100 4271 c0 -5 7 -14 15 -21 13 -10 15 -9 15 9 0 12 -6 21 -15 21
-8 0 -15 -4 -15 -9z"/>
          <path d="M2580 4260 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
          <path d="M5142 4228 c3 -7 13 -15 24 -17 16 -3 17 -1 5 13 -16 19 -34 21 -29
4z"/>
          <path d="M2041 4214 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path d="M5140 4096 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"/>
          <path d="M2100 4040 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
          <path d="M2030 3873 c0 -5 7 -18 15 -29 12 -16 14 -16 15 -3 0 9 -7 22 -15 29
-8 7 -15 8 -15 3z"/>
          <path d="M2460 3842 c0 -14 11 -21 26 -15 8 3 11 9 8 14 -7 11 -34 12 -34 1z" />
          <path d="M2495 3810 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
          <path d="M2561 3786 c2 -2 24 -6 49 -10 31 -4 40 -3 30 4 -13 9 -89 15 -79 6z" />
          <path d="M6540 3392 c-14 -1 -89 -12 -168 -22 -79 -11 -159 -20 -178 -20 -69
0 -89 -59 -29 -84 22 -9 36 -23 39 -38 14 -65 26 -196 28 -300 3 -109 2 -118
-19 -140 -28 -29 -28 -40 -5 -61 17 -16 26 -16 113 -2 52 8 141 20 199 25 131
13 194 33 242 78 46 43 62 89 55 155 -7 58 -30 87 -94 118 -49 24 -52 33 -20
67 18 19 22 35 22 85 0 65 -16 95 -60 120 -24 13 -91 23 -125 19z m-46 -107
c52 -53 32 -175 -30 -175 -30 0 -33 5 -44 96 -8 60 -7 80 3 86 20 13 54 9 71
-7z m41 -261 c42 -17 62 -136 28 -166 -58 -49 -100 -21 -109 74 -4 37 -4 74
-1 82 7 16 51 22 82 10z"/>
          <path d="M6453 3239 c4 -13 7 -39 7 -56 0 -38 17 -44 25 -10 7 29 -8 80 -25
85 -9 3 -11 -2 -7 -19z"/>
          <path d="M6491 2940 c9 -60 15 -71 34 -63 10 3 15 19 15 47 0 44 -13 66 -41
66 -14 0 -15 -7 -8 -50z"/>
          <path d="M5980 3323 c-14 -2 -50 -8 -81 -14 -83 -16 -104 -66 -36 -86 28 -8
33 -14 43 -63 15 -75 34 -219 34 -261 0 -54 -31 -94 -87 -113 -55 -18 -85 -16
-130 7 -49 26 -61 61 -80 223 l-17 146 32 27 c37 30 39 41 14 64 -37 34 -244
14 -309 -29 -32 -21 -28 -45 11 -71 38 -24 40 -31 56 -198 13 -129 17 -148 41
-197 38 -76 128 -118 252 -118 66 0 186 25 197 40 3 4 14 10 26 14 38 12 96
82 109 134 11 41 10 74 -6 213 -10 90 -19 167 -19 171 0 4 14 21 30 36 17 16
30 34 30 39 0 25 -57 43 -110 36z"/>
          <path d="M6086 3221 c-17 -18 -17 -27 -1 -161 11 -88 15 -165 11 -203 -4 -34
-10 -83 -12 -109 -4 -28 -14 -54 -25 -64 -28 -25 -25 -75 6 -104 l25 -24 123
17 c67 9 172 22 232 28 129 13 200 42 246 100 36 46 36 51 2 38 -16 -6 -84
-17 -153 -24 -69 -8 -169 -20 -223 -27 -112 -14 -143 -5 -160 45 -7 23 -4 35
18 68 28 39 28 40 21 162 -8 157 -24 249 -44 265 -23 17 -46 15 -66 -7z"/>
          <path d="M5743 3223 c-13 -2 -23 -9 -23 -16 0 -6 -12 -23 -26 -38 -36 -37 -27
-73 20 -90 33 -12 35 -16 46 -78 6 -36 14 -93 18 -126 l8 -60 33 2 c19 0 43
11 58 25 24 22 25 27 20 108 -3 47 -11 118 -18 158 -11 68 -14 74 -44 86 -18
8 -36 19 -39 25 -7 11 -14 12 -53 4z"/>
          <path d="M5172 3201 c-7 -4 -57 -6 -110 -3 -95 4 -99 3 -161 -29 -168 -89
-177 -270 -18 -359 22 -11 73 -29 115 -39 100 -25 109 -29 115 -52 8 -30 -23
-60 -70 -66 -73 -10 -107 3 -167 63 -56 56 -71 61 -90 31 -13 -21 6 -98 35
-138 28 -39 51 -50 128 -60 168 -21 332 46 382 156 24 51 24 93 0 147 -30 70
-76 98 -206 124 -49 10 -98 24 -107 32 -41 34 -6 92 55 92 31 0 90 -27 126
-59 32 -28 77 -28 92 0 18 35 2 87 -42 130 -41 40 -54 45 -77 30z"/>
          <path d="M5334 3079 c1 -46 -2 -54 -31 -78 l-32 -26 40 -33 c49 -42 71 -91 71
-163 0 -55 -17 -104 -50 -141 -36 -42 81 -127 205 -149 69 -12 175 0 253 28
71 26 160 96 160 126 0 2 -26 -5 -57 -16 -44 -15 -85 -20 -168 -21 -128 -1
-186 18 -248 82 -48 50 -63 89 -77 207 -7 55 -15 124 -18 154 -5 38 -13 57
-28 67 -20 14 -20 13 -20 -37z"/>
          <path d="M4702 3044 c-44 -35 -82 -103 -89 -161 -6 -56 18 -124 58 -164 21
-21 22 -22 3 -35 -39 -27 -44 -36 -44 -82 0 -53 35 -135 73 -169 74 -67 323
-54 439 23 51 34 95 80 86 90 -3 2 -22 -4 -44 -13 -57 -25 -295 -26 -341 0
-39 21 -78 85 -94 155 -15 62 -3 97 37 112 l26 10 -30 34 c-41 47 -56 97 -48
167 3 33 5 59 3 59 -1 0 -17 -12 -35 -26z"/>
          <path d="M5040 3047 c0 -11 17 -19 55 -27 69 -14 86 -10 50 14 -39 26 -105 34
-105 13z"/>
          <path d="M4900 2749 c0 -4 14 -19 31 -33 23 -20 41 -26 77 -26 63 0 87 15 49
29 -16 6 -34 11 -40 11 -7 0 -36 6 -65 14 -29 7 -52 10 -52 5z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
